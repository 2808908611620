import { BookmarkList } from "@/app/core/models/domain/BookmarkList";
import { Actions, createComposable, Getters, Module, Mutations } from "vuex-smart-module";
import { container } from "tsyringe";
import { BookmarkService } from "@/app/core/services/BookmarkService";

export class BookmarkState {
  bookmarkList: BookmarkList | null = null;
  bookmarkListCount: number = 0;
}
export class BookmarkGetters extends Getters<BookmarkState> {
  get bookmarkList(): BookmarkList | null {
    return this.state.bookmarkList;
  }
  get bookmarksCount(): number {
    return this.state.bookmarkListCount;
  }
}
export class BookmarkMutations extends Mutations<BookmarkState> {
  setBookmarkList(bookmarkList: BookmarkList): void {
    this.state.bookmarkList = bookmarkList;
  }
  setBookmarkListCount(count: number): void {
    this.state.bookmarkListCount = count;
  }
}
export class BookmarkActions extends Actions<
  BookmarkState,
  BookmarkGetters,
  BookmarkMutations,
  BookmarkActions
> {
  async getBookmarkList(): Promise<void> {
    const response = await container.resolve(BookmarkService).read<BookmarkList>();
    this.mutations.setBookmarkList(response);
    this.mutations.setBookmarkListCount(response.bookmarksCount);
  }
  async addBookmark(orderId: number): Promise<void> {
    const response = await container.resolve(BookmarkService).addBookmark(orderId);
    this.mutations.setBookmarkList(response);
    this.mutations.setBookmarkListCount(response.bookmarksCount);
  }
  async deleteBookmark(orderId: number): Promise<void> {
    const response = await container.resolve(BookmarkService).delete(orderId);
    this.mutations.setBookmarkList(response);
    this.mutations.setBookmarkListCount(response.bookmarksCount);
  }
}
export const bookmarkModule = new Module({
  state: BookmarkState,
  getters: BookmarkGetters,
  mutations: BookmarkMutations,
  actions: BookmarkActions,
});
export const useBookmarkModule = createComposable(bookmarkModule);
