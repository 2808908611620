import { SavedSearch } from "@/app/core/models/domain/SavedSearch";
import { SavedSearchService } from "@/app/core/services/SavedSearchService";
import { container } from "tsyringe";
import { Actions, createComposable, Getters, Module, Mutations } from "vuex-smart-module";
import { SavedSearchRequestContract } from "@/app/core/interfaces/services/saved-search/SavedSearchRequestContract";

export class SavedSearchState {
  savedSearches: SavedSearch[] | null;
  savedSearchesCount: number;
}
export class SavedSearchGetters extends Getters<SavedSearchState> {
  get savedSearches(): SavedSearch[] | null {
    return this.state.savedSearches;
  }
  get savedSearchesCount(): number {
    return this.state.savedSearchesCount;
  }
}
export class SavedSearchMutations extends Mutations<SavedSearchState> {
  setSavedSearches(payload: SavedSearch[]): void {
    this.state.savedSearches = payload;
  }
  setSavedSearchesCount(payload: number): void {
    this.state.savedSearchesCount = payload;
  }
}
export class SavedSearchActions extends Actions<
  SavedSearchState,
  SavedSearchGetters,
  SavedSearchMutations,
  SavedSearchActions
> {
  async getSavedSearches(): Promise<void> {
    const response = await container.resolve(SavedSearchService).all<SavedSearch[]>();
    this.mutations.setSavedSearches(response);
    this.mutations.setSavedSearchesCount(response.length);
  }

  async createSavedSearch(data: SavedSearchRequestContract): Promise<void> {
    await container.resolve(SavedSearchService).create<SavedSearchRequestContract>(data);
    this.mutations.setSavedSearchesCount(this.state.savedSearchesCount + 1);
  }

  async deleteSavedSearchById(id: number): Promise<void> {
    await container.resolve(SavedSearchService).delete(id);
    if (this.state.savedSearches) {
      const updatedData = this.state.savedSearches.filter((el) => el.id !== id);
      this.mutations.setSavedSearches(updatedData);
      this.mutations.setSavedSearchesCount(this.state.savedSearchesCount - 1);
    }
  }
}

export const savedSearchModule = new Module({
  state: SavedSearchState,
  getters: SavedSearchGetters,
  mutations: SavedSearchMutations,
  actions: SavedSearchActions,
});

export const useSavedSearchModule = createComposable(savedSearchModule);
