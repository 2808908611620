import { SourceType } from "@/app/core/utils/enumDisplayRender";

export enum VehicleType {
  ATV = "ATV",
  Boat = "BOAT",
  Car = "CAR",
  HeavyEquipment = "HEAVY_EQUIPMENT",
  LargeYacht = "LARGE_YACHT",
  Motorcycle = "MOTORCYCLE",
  Pickup = "PICKUP",
  RV = "RV",
  SUV = "SUV",
  TravelTrailer = "TRAVEL_TRAILER",
  Van = "VAN",
  Other = "OTHER",
}
export const VehicleTypeDisplay: SourceType[] = [
  { name: "ATV", value: VehicleType.ATV },
  { name: "Boat", value: VehicleType.Boat },
  { name: "Car", value: VehicleType.Car },
  { name: "Heavy Equipment", value: VehicleType.HeavyEquipment },
  { name: "Large Yacht", value: VehicleType.LargeYacht },
  { name: "Motorcycle", value: VehicleType.Motorcycle },
  { name: "Pickup", value: VehicleType.Pickup },
  { name: "SUV", value: VehicleType.SUV },
  { name: "RV", value: VehicleType.RV },
  { name: "Travel Trailer", value: VehicleType.TravelTrailer },
  { name: "Van", value: VehicleType.Van },
  { name: "Other", value: VehicleType.Other },
];
