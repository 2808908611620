import { RouteRecordRaw } from "vue-router";
import { UserRoleEnum } from "@/app/core/enums/UserRoleEnum";

export const routePrivateProfile: Array<RouteRecordRaw> = [
  {
    path: "profile",
    name: "ProfileLayout",
    component: () => import("@/app/private/pages/profile/ProfileLayout.vue"),
    meta: {
      requireAuth: true,
      roles: [UserRoleEnum.Shipper, UserRoleEnum.Carrier, UserRoleEnum.Admin],
    },
    children: [
      {
        path: "",
        name: "Profile",
        component: () => import("@/app/private/pages/profile/pages/my-profile/MyProfile.vue"),
      },
      {
        path: "company",
        name: "Company",
        component: () =>
          import("@/app/private/pages/profile/pages/my-profile/pages/CompanyInfo.vue"),
      },
      {
        path: "contact",
        name: "Contact",
        component: () =>
          import("@/app/private/pages/profile/pages/my-profile/pages/ContactInfo.vue"),
      },
      {
        path: "insurance",
        name: "Insurance",
        component: () =>
          import("@/app/private/pages/profile/pages/my-profile/pages/InsuranceInfo.vue"),
      },
      {
        path: "preferred-routes",
        name: "PreferredRoutes",
        component: () =>
          import("@/app/private/pages/profile/pages/my-profile/pages/PreferredRoutesInfo.vue"),
      },
      {
        path: "equipment-route",
        name: "EquipmentRoute",
        component: () =>
          import("@/app/private/pages/profile/pages/my-profile/pages/EquipmentRouteInfo.vue"),
      },
      {
        path: "insurance",
        name: "Insurance",
        component: () =>
          import("@/app/private/pages/profile/pages/my-profile/pages/InsuranceInfo.vue"),
      },
      {
        path: "billing",
        name: "Billing",
        component: () => import("@/app/private/pages/profile/pages/my-billing/MyBilling.vue"),
      },
      {
        path: "billing-edit",
        name: "BillingEdit",
        component: () =>
          import("@/app/private/pages/profile/pages/my-billing/pages/BillingInfo.vue"),
      },
      {
        path: "ratings",
        name: "Ratings",
        component: () => import("@/app/private/pages/profile/pages/my-ratings/MyRatings.vue"),
      },
      {
        path: "documents",
        name: "Documents",
        component: () =>
          import("@/app/private/pages/profile/pages/my-documents/MyDocuments.vue"),
      },
      {
        path: "security",
        name: "Security",
        component: () =>
          import("@/app/private/pages/profile/pages/change-password/ChangePassword.vue"),
      },
    ],
  },
];
