import { RouteRecordRaw } from "vue-router";
import { UserRoleEnum } from "@/app/core/enums/UserRoleEnum";

export const routePrivateCheckRoutes: Array<RouteRecordRaw> = [
  {
    path: "check-routes",
    name: "CheckRoutes",
    meta: {
      requireAuth: true,
      roles: [UserRoleEnum.Carrier],
    },
    redirect: (to) => {
      return { name: "CheckRoutesMy" };
    },
    component: () => import("@/app/private/pages/check-routes/CheckRoutes.vue"),
    children: [
      {
        path: "",
        name: "CheckRoutesMy",
        component: () => import("@/app/private/pages/check-routes/pages/CheckRoutesMy.vue"),
      },
      {
        path: "popular",
        name: "CheckRoutesPopular",
        component: () =>
          import("@/app/private/pages/check-routes/pages/CheckRoutesPopular.vue"),
      },
      {
        path: "highest",
        name: "CheckRoutesHighest",
        component: () =>
          import("@/app/private/pages/check-routes/pages/CheckRoutesHighest.vue"),
      },
    ],
  },
];
