import { SourceType } from "@/app/core/utils/enumDisplayRender";

export enum PaymentTypeEnum {
  ACH = "ACH",
  Check = "CHECK",
  Zelle = "ZELLE",
  Cashapp = "CASHAPP",
  All = "ALL",
  COD = "COD",
  COP = "COP",
  Invoice = "INVOICE",
  QuickPay = "QUICKPAY",
  Venmo = "VENMO",
}

export const PaymentTypeDisplay: SourceType[] = [
  {
    value: PaymentTypeEnum.ACH,
    name: "ACH",
  },
  {
    value: PaymentTypeEnum.Check,
    name: "Check",
  },
  {
    value: PaymentTypeEnum.Zelle,
    name: "Zelle",
  },
  {
    value: PaymentTypeEnum.Cashapp,
    name: "Cashapp",
  },
];

export const UpdatedPaymentTypeDisplay: SourceType[] = [
  {
    value: PaymentTypeEnum.COD,
    name: "COD",
  },
  {
    value: PaymentTypeEnum.COP,
    name: "COP",
  },
  {
    value: PaymentTypeEnum.Invoice,
    name: "Invoice",
  },
  {
    value: PaymentTypeEnum.QuickPay,
    name: "QuickPay",
  },
];
