export enum DaysTypeEnum {
  OneDay = 1,
  TwoDays = 2,
  ThreeDays = 3,
  FourDays = 4,
  FiveDays = 5,
  SixDays = 6,
  SevenDays = 7,
  EightDays = 8,
  NineDays = 9,
  TenDays = 10,
  FifteenDays = 15,
  ThirtyDays = 30,
  AllTime = 0,
}

export const DaysTypeDisplay = [
  {
    value: DaysTypeEnum.OneDay,
    name: "1 day",
  },
  {
    value: DaysTypeEnum.TwoDays,
    name: "2 days",
  },
  {
    value: DaysTypeEnum.ThreeDays,
    name: "3 days",
  },
  {
    value: DaysTypeEnum.FourDays,
    name: "4 days",
  },
  {
    value: DaysTypeEnum.FiveDays,
    name: "5 days",
  },
  {
    value: DaysTypeEnum.SixDays,
    name: "6 days",
  },

  {
    value: DaysTypeEnum.SevenDays,
    name: "7 days",
  },
  {
    value: DaysTypeEnum.EightDays,
    name: "8 days",
  },
  {
    value: DaysTypeEnum.NineDays,
    name: "9 days",
  },

  {
    value: DaysTypeEnum.TenDays,
    name: "10 days",
  },
  {
    value: DaysTypeEnum.FifteenDays,
    name: "15 days",
  },
  {
    value: DaysTypeEnum.ThirtyDays,
    name: "30 days",
  },
  {
    value: DaysTypeEnum.AllTime,
    name: "All Time",
  },
];

export const PostedWithinTypeDisplay = [
  {
    value: DaysTypeEnum.OneDay,
    name: "1 day",
  },
  {
    value: DaysTypeEnum.TwoDays,
    name: "2 days",
  },
  {
    value: DaysTypeEnum.ThreeDays,
    name: "3 days",
  },
  {
    value: DaysTypeEnum.FourDays,
    name: "4 days",
  },
  {
    value: DaysTypeEnum.FiveDays,
    name: "5 days",
  },
  {
    value: DaysTypeEnum.SixDays,
    name: "6 days",
  },

  {
    value: DaysTypeEnum.SevenDays,
    name: "7 days",
  },
  {
    value: DaysTypeEnum.EightDays,
    name: "8 days",
  },
  {
    value: DaysTypeEnum.NineDays,
    name: "9 days",
  },

  {
    value: DaysTypeEnum.TenDays,
    name: "10 days",
  },
  {
    value: DaysTypeEnum.FifteenDays,
    name: "15 days",
  },
  {
    value: DaysTypeEnum.ThirtyDays,
    name: "30 days",
  },
];
