import { Order } from "@/app/core/models/domain/Order";
import { Actions, createComposable, Getters, Module, Mutations } from "vuex-smart-module";

export class CheckRouteState {
  orderId: number | null;
  orders: Order[] | null;
  itemCount: number;
  totalItems: number;
  itemsPerPage: number;
  totalPages?: number;
  currentPage: number;
}
export class CheckRouteGetters extends Getters<CheckRouteState> {
  get orderId(): number | null {
    return this.state.orderId;
  }
  get orders(): Order[] | null {
    return this.state.orders;
  }
  get ordersCount(): number {
    return this.state.totalItems ? this.state.totalItems : 0;
  }

  get currentPage(): number {
    return this.state.currentPage ? this.state.currentPage : 1;
  }

  get itemCount(): number {
    return this.state.itemCount ? this.state.itemCount : 0;
  }

  get totalItems(): number {
    return this.state.totalItems ? this.state.totalItems : 0;
  }

  get itemsPerPage(): number {
    return this.state.itemsPerPage ? this.state.itemsPerPage : 50;
  }

  get totalPages(): number {
    return this.state.totalPages ? this.state.totalPages : 0;
  }
}
export class CheckRouteMutations extends Mutations<CheckRouteState> {
  setOrderId(id: number) {
    this.state.orderId = id;
  }
  setOrders(orders: Order[]) {
    this.state.orders = orders;
  }
  setCurrentPage(page: number) {
    this.state.currentPage = page;
  }
  setPageSize(size: number) {
    this.state.itemsPerPage = size;
  }
  setItemCount(itemCount: number) {
    this.state.itemCount = itemCount;
  }
  setTotalItems(totalItems: number) {
    this.state.totalItems = totalItems;
  }
  setItemsPerPage(itemsPerPage: number) {
    this.state.itemsPerPage = itemsPerPage;
  }
  setTotalPages(totalPages: number) {
    this.state.totalPages = totalPages;
  }
}
export class CheckRouteActions extends Actions<
  CheckRouteState,
  CheckRouteGetters,
  CheckRouteMutations,
  CheckRouteActions
> {}
export const checkRouteModule = new Module({
  state: CheckRouteState,
  getters: CheckRouteGetters,
  mutations: CheckRouteMutations,
  actions: CheckRouteActions,
});
export const useCheckRouteModule = createComposable(checkRouteModule);
