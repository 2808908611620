import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { routesPublic } from "@/router/routes/RoutesPublic";
import { routesPrivate } from "@/router/routes/RoutesPrivate";
import { container } from "tsyringe";
import { UserService } from "@/app/core/services/UserService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/login",
  },
  ...routesPublic,
  ...routesPrivate,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { requireAuth, roles } = to.meta;
  if (requireAuth) {
    const response = await container.resolve(UserService).getCurrent();
    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (!roles.includes(response.role)) {
      return next("/login");
    }
  }
  next();
});

export default router;
