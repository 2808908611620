import { RestService } from "@/app/core/services/core/RestService";
import { Billing } from "@/app/core/models/domain/Billing";
import { PaymentTypeEnum } from "@/app/core/enums/PaymentTypeEnum";
import { AchContract } from "@/app/core/interfaces/services/billing/AchContract";
import { CashappContract } from "@/app/core/interfaces/services/billing/CashappContract";
import { CheckContract } from "@/app/core/interfaces/services/billing/CheckContract";
import { ZelleContract } from "@/app/core/interfaces/services/billing/ZelleContract";
import { PaymentProfile } from "@/app/core/models/domain/PaymentProfile";

export class BillingService extends RestService<Billing> {
  constructor() {
    super("/billings", "billing", "ru");
  }

  async getPayments(): Promise<PaymentProfile[]> {
    return await this.httpGet(`${this.apiHref()}/payment`);
  }
  async updatePayment(
    type: PaymentTypeEnum,
    data: AchContract | CashappContract | CheckContract | ZelleContract
  ): Promise<any> {
    return await this.httpPatch(`${this.apiHref()}/payment/${type}`, data);
  }
}
