import { RouteRecordRaw } from "vue-router";

export const routesPublic: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Public",
    component: () => import("../../app/public/Public.vue"),
    children: [
      {
        path: "",
        component: () => import("../../app/public/pages/auth/AuthLayout.vue"),
        children: [
          {
            path: "login",
            component: () => import("../../app/public/pages/auth/pages/Login.vue"),
            name: "Login",
            meta: {
              requireAuth: false,
            },
          },
          {
            path: "register",
            component: () => import("../../app/public/pages/auth/pages/Register.vue"),
            name: "Register",
            props: true,
            meta: {
              requireAuth: false,
            },
          },
          {
            path: "check-role",
            component: () => import("../../app/public/pages/auth/pages/CheckRole.vue"),
            name: "CheckRole",
            meta: {
              requireAuth: false,
            },
          },
          {
            path: "check-mc",
            component: () => import("../../app/public/pages/auth/pages/CheckMc.vue"),
            name: "CheckMc",
            props: true,
            meta: {
              requireAuth: false,
            },
          },
          {
            path: "mc-not-found",
            component: () => import("../../app/public/pages/auth/pages/McNotFound.vue"),
            name: "McNotFound",
            meta: {
              requireAuth: false,
            },
          },
          {
            path: "reset-password/:id/:token",
            name: "ResetPassword",
            meta: {
              requireAuth: false,
            },
            component: () => import("../../app/public/pages/auth/pages/ResetPassword.vue"),
          },
          {
            path: "forgot-password",
            name: "ForgotPassword",
            meta: {
              requireAuth: false,
            },
            component: () => import("../../app/public/pages/auth/pages/ForgotPassword.vue"),
          },
        ],
      },
      {
        path: "terms-and-conditions",
        component: () => import("../../app/public/pages/TermsAndConditions.vue"),
        name: "TermsAndConditions",
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "order-details",
        component: () =>
          import("../../app/public/pages/search-for-orders/OrderDetailsPublicLayout.vue"),
        children: [
          {
            path: ":id/:token",
            name: "OrderDetailsAcceptFromURL",
            meta: {
              requireAuth: false,
            },
            component: () =>
              import(
                "../../app/public/pages/search-for-orders/pages/OrderDetailsAcceptFromURL.vue"
              ),
          },
        ],
      },
    ],
  },
];
