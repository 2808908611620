import { SourceType } from "@/app/core/utils/enumDisplayRender";

export enum SearchOrdersSort {
  PostedAsc = "POSTED_ASC",
  PostedDesc = "POSTED_DESC",
  PriceAsc = "PRICE_ASC",
  PriceDesc = "PRICE_DESC",
}
export const SearchOrdersSortDisplay: SourceType[] = [
  {
    value: SearchOrdersSort.PostedDesc,
    name: "Posted date: Earliest first",
  },
  {
    value: SearchOrdersSort.PostedAsc,
    name: "Posted date: Latest first",
  },
  {
    value: SearchOrdersSort.PriceAsc,
    name: "Price: Lowest to highest",
  },
  {
    value: SearchOrdersSort.PriceDesc,
    name: "Price: Highest to lowest",
  },
];
