export enum SearchOrdersType {
  Location = "LOCATION",
  State = "STATE",
  Region = "REGION",
  Coast = "COAST",
  OrderID = "ORDER_ID",
}

export const SearchOrdersTypeDisplay = [
  { name: "Location", value: SearchOrdersType.Location },
  { name: "State", value: SearchOrdersType.State },
  { name: "Region", value: SearchOrdersType.Region },
  { name: "Coast", value: SearchOrdersType.Coast },
  { name: "Order ID", value: SearchOrdersType.OrderID },
];

export const PreferredRoutesTypeDisplay = [
  { name: "State", value: SearchOrdersType.State },
  { name: "Region", value: SearchOrdersType.Region },
  { name: "Coast", value: SearchOrdersType.Coast },
];
