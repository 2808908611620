import { Company } from "@/app/core/models/domain/Company";
import { Actions, createComposable, Getters, Module, Mutations } from "vuex-smart-module";
import { container } from "tsyringe";
import { CompanyService } from "@/app/core/services/CompanyService";
import { UserService } from "@/app/core/services/UserService";
import { UserResponseContract } from "@/app/core/interfaces/services/user/UserResponseContract";
import { EnvironmentService } from "@/app/core/services/core/EnvironmentService";
import { Insurance } from "@/app/core/models/domain/Insurance";
import { InsuranceService } from "@/app/core/services/InsuranceService";
import { DocumentService } from "@/app/core/services/DocumentService";
import { Document } from "@/app/core/models/domain/Document";
import { DocumentType } from "@/app/core/enums/DocumentType";
import { Billing } from "@/app/core/models/domain/Billing";
import { BillingService } from "@/app/core/services/BillingService";
import { PaymentProfile } from "@/app/core/models/domain/PaymentProfile";
import { PaymentTypeEnum } from "@/app/core/enums/PaymentTypeEnum";
import { Equipment } from "@/app/core/models/domain/Equipment";
import { EquipmentService } from "@/app/core/services/EquipmentService";
import { PreferredRouteService } from "@/app/core/services/PreferredRouteService";
import { PreferredRouteContract } from "@/app/core/interfaces/services/check-route/PreferredRouteContract";
import { UpdatePreferredRouteContract } from "@/app/core/interfaces/services/preferred-routes/UpdatePreferredRouteContract";
import { CreatePreferredRouteContract } from "@/app/core/interfaces/services/preferred-routes/CreatePreferredRouteContract";

export class ProfileState {
  user: UserResponseContract | null = null;
  company: Company | null = null;
  insurance: Insurance | null = null;
  documents: Document[] | null = null;
  billing: Billing | null = null;
  paymentProfiles: PaymentProfile[] | null = null;
  equipment: Equipment | null = null;
  preferredRoutes: PreferredRouteContract[] = [];
}
export class ProfileGetters extends Getters<ProfileState> {
  get getUser(): UserResponseContract | null {
    return this.state.user;
  }
  get company(): Company | null {
    return this.state.company;
  }
  get getInsurance(): Insurance | null {
    return this.state.insurance;
  }
  get getDocuments(): Document[] | null {
    return this.state.documents;
  }
  get getCoi(): Document | undefined {
    return this.state.documents?.find((x) => x.type === DocumentType.COI);
  }
  get getW9(): Document | undefined {
    return this.state.documents?.find((x) => x.type === DocumentType.W9);
  }
  get getVoidCheck(): Document | undefined {
    return this.state.documents?.find((x) => x.type === DocumentType.VoidCheck);
  }
  get getAvatarHref(): string {
    if (!this.state.user?.imgPath) {
      return "/static/icons/shared/avatar.svg";
    }
    return (
      container.resolve(EnvironmentService).avatarsBucketOrigin + this.state.user?.imgPath
    );
  }
  get getBilling(): Billing | null {
    return this.state.billing;
  }
  get getPaymentProfiles(): PaymentProfile[] | null {
    return this.state.paymentProfiles;
  }
  get getAch(): PaymentProfile | undefined {
    return this.state.paymentProfiles?.find((x) => x.paymentType.name === PaymentTypeEnum.ACH);
  }
  get getCheck(): PaymentProfile | undefined {
    return this.state.paymentProfiles?.find(
      (x) => x.paymentType.name === PaymentTypeEnum.Check
    );
  }
  get getZelle(): PaymentProfile | undefined {
    return this.state.paymentProfiles?.find(
      (x) => x.paymentType.name === PaymentTypeEnum.Zelle
    );
  }
  get getVenmo(): PaymentProfile | undefined {
    return this.state.paymentProfiles?.find(
      (x) => x.paymentType.name === PaymentTypeEnum.Venmo
    );
  }
  get getCashapp(): PaymentProfile | undefined {
    return this.state.paymentProfiles?.find(
      (x) => x.paymentType.name === PaymentTypeEnum.Cashapp
    );
  }
  get getInvoice(): PaymentProfile | undefined {
    return this.state.paymentProfiles?.find(
      (x) => x.paymentType.name === PaymentTypeEnum.Invoice
    );
  }
  get getQuickPay(): PaymentProfile | undefined {
    return this.state.paymentProfiles?.find(
      (x) => x.paymentType.name === PaymentTypeEnum.QuickPay
    );
  }
  get getEquipment(): Equipment | null {
    return this.state.equipment;
  }
  get getPreferredRoutes(): PreferredRouteContract[] {
    return this.state.preferredRoutes;
  }
}
export class ProfileMutations extends Mutations<ProfileState> {
  setUser(payload: UserResponseContract | null) {
    this.state.user = payload;
  }
  setCompany(payload: Company) {
    this.state.company = payload;
  }
  setInsurance(payload: Insurance) {
    this.state.insurance = payload;
  }
  setDocuments(payload: Document[]) {
    this.state.documents = payload;
  }
  setBilling(payload: Billing) {
    this.state.billing = payload;
  }
  setPaymentProfiles(payload: PaymentProfile[]) {
    this.state.paymentProfiles = payload;
  }
  setEquipment(payload: Equipment) {
    this.state.equipment = payload;
  }
  setPreferredRoutes(payload: PreferredRouteContract[]): void {
    this.state.preferredRoutes = payload;
  }
  addPreferredRoutes(payload: PreferredRouteContract): void {
    this.state.preferredRoutes?.push(payload);
  }

  updatePreferredRoutes(payload: PreferredRouteContract): void {
    const index = this.state.preferredRoutes!.findIndex((el) => el.id == payload.id);
    this.state.preferredRoutes![index] = payload;
  }
}
export class ProfileActions extends Actions<
  ProfileState,
  ProfileGetters,
  ProfileMutations,
  ProfileActions
> {
  async fetchUser() {
    const response = await container.resolve(UserService).getCurrent();
    this.mutations.setUser(response);
  }
  async fetchUserCompany() {
    const response = await container.resolve(CompanyService).read();
    this.mutations.setCompany(response);
  }
  async fetchInsurance() {
    const response = await container.resolve(InsuranceService).read();
    this.mutations.setInsurance(response);
  }
  async fetchDocuments() {
    const response = await container.resolve(DocumentService).read();
    this.mutations.setDocuments(response);
  }
  async fetchBilling() {
    const response = await container.resolve(BillingService).read();
    this.mutations.setBilling(response);
  }
  async fetchPaymentProfiles() {
    const response = await container.resolve(BillingService).getPayments();
    this.mutations.setPaymentProfiles(response);
  }
  async fetchEquipment() {
    const response = await container.resolve(EquipmentService).read();
    this.mutations.setEquipment(response);
  }
  async fetchPreferredRoutes(): Promise<void> {
    const response = await container
      .resolve(PreferredRouteService)
      .all<PreferredRouteContract[]>();
    this.mutations.setPreferredRoutes(response);
  }

  async createPreferredRoutes(payload: CreatePreferredRouteContract): Promise<void> {
    const response = await container
      .resolve(PreferredRouteService)
      .create<CreatePreferredRouteContract>(payload);
    this.mutations.addPreferredRoutes(response);
  }

  async updatePreferredRoutes(payload: {
    id: number;
    data: UpdatePreferredRouteContract;
  }): Promise<void> {
    const response = await container
      .resolve(PreferredRouteService)
      .updatePreferredRoute(payload.id, {
        pickup: payload.data.pickup,
        destination: payload.data.destination,
        pickupType: payload.data.pickupType,
        destinationType: payload.data.destinationType,
      });
    this.mutations.updatePreferredRoutes(response);
  }

  async deletePreferredRouteById(id: number): Promise<void> {
    await container.resolve(PreferredRouteService).delete(id);
    if (this.state.preferredRoutes) {
      const updatedData = this.state.preferredRoutes.filter((el) => el.id !== id);
      this.mutations.setPreferredRoutes(updatedData);
    }
  }
}
export const profileModule = new Module({
  state: ProfileState,
  getters: ProfileGetters,
  mutations: ProfileMutations,
  actions: ProfileActions,
});
export const useProfileModule = createComposable(profileModule);
