import { RestService } from "@/app/core/services/core/RestService";
import { PreferredRouteContract } from "@/app/core/interfaces/services/check-route/PreferredRouteContract";
import { UpdatePreferredRouteContract } from "@/app/core/interfaces/services/preferred-routes/UpdatePreferredRouteContract";

export class PreferredRouteService extends RestService<PreferredRouteContract> {
  constructor() {
    super("/preferred-routes", "preferred-routes", "cad");
  }

  async updatePreferredRoute(
    id: number,
    data: UpdatePreferredRouteContract
  ): Promise<PreferredRouteContract> {
    return await this.httpPut(`${this.apiHref()}/${id}`, data);
  }
}
