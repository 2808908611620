import { RouteRecordRaw } from "vue-router";
import { UserRoleEnum } from "@/app/core/enums/UserRoleEnum";

export const routePrivateCarrierNetwork: Array<RouteRecordRaw> = [
  {
    path: "carrier-network",
    name: "CarrierNetwork",
    meta: {
      requireAuth: true,
      roles: [UserRoleEnum.Shipper],
    },
    component: () => import("@/app/private/pages/carrier-network/CarrierNetwork.vue"),
  },
];
