import { RestService } from "@/app/core/services/core/RestService";
import { BookmarkList } from "@/app/core/models/domain/BookmarkList";

export class BookmarkService extends RestService<BookmarkList> {
  constructor() {
    super("/bookmarks", "bookmarks", "rd");
  }

  async addBookmark(orderId: number): Promise<BookmarkList> {
    return await this.httpPost(`${this.apiHref()}/${orderId}`);
  }
}
