import { RouteRecordRaw } from "vue-router";
import { UserRoleEnum } from "@/app/core/enums/UserRoleEnum";

export const routePrivateMyVehicles: Array<RouteRecordRaw> = [
  {
    path: "my-vehicles",
    name: "MyVehicles",
    meta: {
      requireAuth: true,
      roles: [UserRoleEnum.Shipper],
    },
    component: () => import("@/app/private/pages/my-vehicles/MyVehicles.vue"),
  },
];
