import "core-js";
import { createApp } from "vue";
import App from "./app/App.vue";
import router from "@/router/Router";
import Maska from "maska";
import { VueReCaptcha } from "vue-recaptcha-v3";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import { store } from "@/store/Store";
import "vue3-date-time-picker/dist/main.css";
import { Tooltip } from "floating-vue";
import "floating-vue/dist/style.css";
import { EnvironmentService } from "@/app/core/services/core/EnvironmentService";
import { container } from "tsyringe";

const options: PluginOptions = {
  position: POSITION.TOP_RIGHT,
};

const app = createApp(App);
const environmentService = container.resolve(EnvironmentService);

app.component("base-tooltip", Tooltip);

app
  .use(Toast, options)
  .use(store)
  .use(router)
  .use(Maska)
  .use(VueReCaptcha, {
    siteKey: environmentService.recaptchaSiteKey,
    loaderOptions: {
      autoHideBadge: true,
    },
  })
  .mount("#app");
