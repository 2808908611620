import { RouteRecordRaw } from "vue-router";
import { UserRoleEnum } from "@/app/core/enums/UserRoleEnum";

export const routePrivateUsers: Array<RouteRecordRaw> = [
  {
    path: "users",
    name: "Users",
    meta: {
      requireAuth: true,
      roles: [UserRoleEnum.Admin],
    },
    props: true,
    component: () => import("@/app/private/pages/users/Users.vue"),
    children: [
      {
        path: "add",
        name: "AddUsersCard",
        props: true,
        component: () => import("@/app/private/pages/users/components/AddUserCard.vue"),
      },
      {
        path: "edit/:id",
        name: "EditUsersCard",
        props: true,
        component: () => import("@/app/private/pages/users/components/EditUserCard.vue"),
      },
    ],
  },
];
