export enum UserRoleEnum {
  Shipper = "SHIPPER",
  Carrier = "CARRIER",
  Admin = "ADMIN",
  Dispatcher = "DISPATCHER",
}

export const UserRoleDisplay = [
  { name: "Shipper", value: UserRoleEnum.Shipper },
  { name: "Carrier", value: UserRoleEnum.Carrier },
  { name: "Admin", value: UserRoleEnum.Admin },
  { name: "Dispatcher", value: UserRoleEnum.Dispatcher },
];
