import { RestService } from "@/app/core/services/core/RestService";
import { FileResponseContract } from "@/app/core/interfaces/services/core/FileResponseContract";

export class DocumentService extends RestService {
  constructor() {
    super("/documents", "document", "ruda");
  }

  async downloadDocument(id: number): Promise<FileResponseContract> {
    return await this.httpGetBlob(`${this.apiHref()}/${id}/download`);
  }
}
