import { RouteRecordRaw } from "vue-router";
import { routePrivateProfile } from "@/router/routes/private/RoutePrivateProfile";
import { routePrivateDispatched } from "@/router/routes/private/RoutePrivateDispatched";
import { routePrivateCheckRoutes } from "@/router/routes/private/RoutePrivateCheckRoutes";
import { routePrivateSearch } from "@/router/routes/private/RoutePrivateSearch";
import { routePrivateUsers } from "@/router/routes/private/RoutePrivateUsers";
import { routePrivateMyVehicles } from "@/router/routes/private/RoutePrivateMyVehicles";
import { routePrivateCarrierNetwork } from "@/router/routes/private/RoutePrivateCarrierNetwork";

export const routesPrivate: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Private",
    component: () => import("@/app/private/Private.vue"),
    children: [
      ...routePrivateSearch,
      ...routePrivateCheckRoutes,
      ...routePrivateDispatched,
      ...routePrivateProfile,
      ...routePrivateUsers,
      ...routePrivateMyVehicles,
      ...routePrivateCarrierNetwork,
    ],
  },
];
